<template>
    <div >
        <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de style-scuer mb-3">
                    <div class="
                            sausage-chart__item_body_top
                          
                            mt-2
                            mb-2
                        ">
                        {{ exhibition.deals ? exhibition.deals : 0 }}
                    </div>
                    <h4 class=" dashboard-tile__item-title short">
                        <span class="dashboard-tile__item-title-txt">   {{$t('message.deals')}}   </span>
                    </h4>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de style-scuer mb-3">
                    <div class="
                            sausage-chart__item_body_top
                          
                            mt-2
                            mb-2
                        ">
                        {{ exhibition.participants ? exhibition.participants.length : 0 }}
                    </div>
                    <h4 class=" dashboard-tile__item-title short">
                        <span class="dashboard-tile__item-title-txt">  {{$t('message.managers')}}  </span>
                    </h4>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de style-scuer mb-3">
                    <div class="
                            sausage-chart__item_body_top
                          
                            mt-2
                            mb-2
                        ">
                        {{ exhibition.clients ? exhibition.clients : 0 }}
                    </div>
                    <h4 class=" dashboard-tile__item-title short">
                        <span class="dashboard-tile__item-title-txt">   {{$t('message.clients')}}  </span>
                    </h4>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de ba-us style-scuer mb-3">
                    <div class="
                            sausage-chart__item_body_top
                          
                            mt-2
                            mb-2
                        ">
                        {{ exhibition.completedDeals ? exhibition.completedDeals : 0 }}
                    </div>
                    <h4 class=" dashboard-tile__item-title short">
                        <span class="dashboard-tile__item-title-txt">  {{$t('message.succesfull')}}  </span>
                    </h4>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="chart-exhibitionHome style-scuer mb-3 m-hight">
                    <div class="filterblock_s mb-3 ">
                        <h4
                            class="
                                dashboard-tile__item-title
                                short
                            "
                        >
                            <span

                                class="
                                    dashboard-tile__item-title-txt
                                  
                                "
                            >
                            {{$t('message.managers')}}
                            </span>
                        </h4>
                        <div class="filter_home">
                            <el-radio-group
                                v-model="dealsByManagers"
                                size="medium"
                                class=""
                            >
                                <el-radio-button label="monthly"
                                    >{{ $t("message.monthly") }}
                                </el-radio-button>
                                <el-radio-button label="yearly"
                                    >{{ $t("message.yearly") }}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <CrmMANAGERS :period="dealsByManagers" ></CrmMANAGERS>
                </div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="chart-exhibitionHome style-scuer mb-3 m-hight">
                    <div class="filterblock_s mb-3 ">
                        <h4
                            class="
                                dashboard-tile__item-title
                                short
                            "
                        >
                            <span

                                class="
                                    dashboard-tile__item-title-txt
                                  
                                "
                            >
                            {{$t('message.deal_type')}}
                            </span>
                        </h4>
                        <div class="filter_home">
                            <el-radio-group
                                v-model="dealTypesChartPeriod"
                                size="medium"
                                class=""
                            >
                                <el-radio-button label="monthly"
                                    >{{ $t("message.monthly") }}
                                </el-radio-button>
                                <el-radio-button label="yearly"
                                    >{{ $t("message.yearly") }}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <dealTypesChart
                        :filterForm="filterForm"
                        :period="dealTypesChartPeriod"
                        size="350"
                    ></dealTypesChart>
                </div>
            </el-col>

        </el-row>


        <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">

                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="chart-exhibitionHome style-scuer mb-3">
                    <div class="filterblock_s mb-3 ">
                        <h4
                            class="
                                dashboard-tile__item-title
                                short
                            "
                        >
                            <span

                                class="
                                    dashboard-tile__item-title-txt
                                  
                                "
                            >
                            {{$t('message.clients')}}
                            </span>
                        </h4>
                        <div class="filter_home">
                            <el-radio-group
                                v-model="dealsByClients"
                                size="medium"
                                class=""
                            >
                                <el-radio-button label="monthly"
                                    >{{ $t("message.monthly") }}
                                </el-radio-button>
                                <el-radio-button label="yearly"
                                    >{{ $t("message.yearly") }}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <CrmCilent :period="dealsByClients"></CrmCilent>
                </div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de style-scuer mb-3">
                            <div class="
                                    sausage-chart__item_body_top
                                  
                                    mt-2
                                    mb-2
                                ">
                                {{ exhibition.costTotal |formatMoney(2) }}
                            </div>
                            <h4 class=" dashboard-tile__item-title short">
                                <span class="dashboard-tile__item-title-txt">  {{$t('message.expenses') + ' ' + $t('message.by_amount')}}  </span>
                            </h4>
                        </div>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de style-scuer mb-3">
                            <div class="
                                    sausage-chart__item_body_top
                                  
                                    mt-2
                                    mb-2
                                ">
                                {{ exhibition.money_amount |formatMoney(2) }}
                            </div>
                            <h4 class=" dashboard-tile__item-title short">
                                <span class="dashboard-tile__item-title-txt"> {{$t('message.deals') + ' ' + $t('message.by_amount')}} </span>
                            </h4>
                        </div>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="exhibitionHome-de ba-us style-scuer mb-3">
                            <div class="
                                    sausage-chart__item_body_top
                                  
                                    mt-2
                                    mb-2
                                ">
                               {{ exhibition.completed_money_amount |formatMoney(2) }}
                            </div>
                            <h4 class=" dashboard-tile__item-title short">
                                <span class="dashboard-tile__item-title-txt">  {{$t('message.succesfull') + ' ' + $t('message.by_amount')}}  </span>
                            </h4>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

    </div>
</template>
<script>
// @ is an alias to /src
import CrmMANAGERS from "./components/chart-men";
import CrmCilent from "./components/chart-cilent";
import dealTypesChart from "@/components/chart/dealTypesChart";

import { mapGetters, mapActions } from "vuex";
export default {
    name: "exhibitionHome",
    components: {
        CrmMANAGERS,
        CrmCilent,
        dealTypesChart
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            exhibition: "exhibitions/model",
        }),
        exhibition_id: function () {
            return this.$route.params.exhibition_id
        },
    },
    data() {
        return {
            dealsByClients: 'yearly',
            dealsByManagers: 'yearly',
            dealTypesChartPeriod: 'yearly',
            filterForm: {},
            update: 0,
        };
    },

    created(){
        this.$set(this.filterForm, 'exhibition_id', this.exhibition_id);
        this.$set(this.filterForm, 'period', 'yearly');
    },
    methods: {
        ...mapActions({
        }),
        async fetchData(){

        }
    }
};

</script>
<style>
   .exhibitionHome-de {
        min-height: 100px;
        text-align: right;
    }
    .ba-us{
        background: #459ef7;
        border-color: #fff !important;
    }
    .d-modal-style .chart-exhibitionHome{
        border: 1px dashed #323248 !important;
    }
    .d-modal-style .apexcharts-legend-text{
        color: #fff !important;
    }
    .m-hight{
        min-height: 330px;
    }
</style>
