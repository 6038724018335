<template>
    <div>
        <div id="chart">
            <apexchart type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "exhibitionInfo",
        props: {
            period: {
                default: 'monthly'
            },
        },
        data() {
            return {
                series: [{
                    data: []
                }],
                chartOptions: {
                    chart: {
                    type: 'bar',
                    height: 250
                    },
                    plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            borderRadius: 4,
                            marginBottom: 10,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    colors: ['#775dd0',],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#fff']
                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                        },
                        offsetX: 0,
                            dropShadow: {
                                enabled: true
                            }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff'],
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                    labels: {
                        show: false
                    }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return ''
                            }
                            }
                        }
                    }
                },
            };
        },
        watch: {
            period: {
                handler: async function(val) {
                    await this.updateChartData(val);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapGetters({
                list: "exhibitions/dealsByClients"
            }),
            exhibition_id: function () {
                return this.$route.params.exhibition_id
            },
        },
        methods: {
            ...mapActions({
                updateList: "exhibitions/dealsByClients"
            }),
            updateChartData(period){
                this.updateList({id: this.exhibition_id, period: period})
                .then(res => {
                    this.series[0].data = this.list.map(el => el.deals_count);
                    this.chartOptions = {...this.chartOptions, ...{
                        xaxis: {
                            categories: this.list.map(el => el.full_name)
                        }
                    }}
                });
            }

        }
    };
    
    </script>